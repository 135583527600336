import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { App } from './App';
import './i18n';

if (process.env.REACT_APP_ENVIRONMENT === 'WIZARD') {
    import('./_assets/css/wizard/global.css');
} else {
    import('./_assets/css/global.css');
}

const root = ReactDOM.createRoot(document.getElementById('root'));

console.log('Current Environment:', process.env.REACT_APP_ENVIRONMENT);

root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>,
);
