import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { CardHeader } from '../CardHeader';
import { useMobileDetect } from '../../../hooks';

export const CampaignCardsColumn = ({ workflow, keyTitle, children, t }) => {
    const isMobile = useMobileDetect();
    const [itemWidth, setItemWidth] = useState('');

    useEffect(() => {
        const calculateWidth = () => {
            if (!workflow.etapas || workflow.etapas.length === 0) {
                return;
            }

            const calculatedItemWidth = Math.floor((100 / workflow.etapas.length).toFixed(2));

            setItemWidth(calculatedItemWidth);
        };

        calculateWidth();

        window.addEventListener('resize', calculateWidth);

        return () => window.removeEventListener('resize', calculateWidth);
    }, [workflow.etapas.length]);

    return (
        <Box
            sx={{
                bgcolor: 'var(--white-color)',
                borderRadius: 2,
                flexShrink: 0,
                width: { xs: '100%', md: workflow.etapas.length <= 4 ? `${itemWidth - 0.8}% ` : '350px' },
                maxWidth: { xs: '80%', md: '100%' },
                marginRight: 2,
                marginBottom: 10,
            }}
        >
            <CardHeader title={keyTitle} />
            {children}
        </Box>
    );
};
